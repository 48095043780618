import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { RenderLinkProps } from '@components/renderers'

// eslint-disable-next-line object-curly-newline
const ButtonLink = React.forwardRef(({
  className,
  href,
  children,
  prefetch,
  dataAttrs,
  reference,
  externalLink,
}, ref) => {
  let linkProps = {}

  if (!href) {
    const selfHref = reference
    && RenderLinkProps({ slug: reference.slug, type: reference.__typename })
    const external = externalLink && { href: externalLink }
    linkProps = external || selfHref
  }

  return (
    externalLink
      ? (<a {...linkProps} {...dataAttrs} className={className} target="_blank" rel="noopener noreferrer">{children}</a>)
      : (
        <Link
          href={href || linkProps?.href || ''}
          prefetch={prefetch}
          ref={ref}
        >
          <a className={className} {...dataAttrs}>
            {children}
          </a>
        </Link>
      )
  )
})

ButtonLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  prefetch: PropTypes.bool,
  dataAttrs: PropTypes.object,
  externalLink: PropTypes.string,
  reference: PropTypes.shape({
    __typename: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default ButtonLink
