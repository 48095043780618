import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const IconLiveNew = props => (
  <SvgIcon {...props}>
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={14} cy={14} r={14} fill="#FFBE00" />
      <g opacity={0.7}>
        <path
          d="m14 17.87-1.456 1.403a.436.436 0 0 1-.317.148.458.458 0 0 1-.328-.122.418.418 0 0 1-.128-.314.404.404 0 0 1 .155-.301l1.633-1.572v-2.19a1.628 1.628 0 0 1-.632-.483 1.128 1.128 0 0 1-.25-.724c-.003-.167.03-.332.095-.486.066-.154.164-.295.287-.412.123-.118.27-.211.432-.274.162-.062.335-.094.51-.091.174-.003.348.029.51.091.161.063.308.156.432.274.123.117.22.258.286.412.066.154.099.32.096.486.005.261-.083.517-.25.724a1.628 1.628 0 0 1-.633.483v2.19l1.634 1.558a.42.42 0 0 1 .154.301.401.401 0 0 1-.128.313.44.44 0 0 1-.328.123.456.456 0 0 1-.316-.148L14 17.869Zm0-7.018a3.073 3.073 0 0 0-1.991.707 2.811 2.811 0 0 0-.995 1.791.448.448 0 0 1-.167.264.488.488 0 0 1-.613-.017.348.348 0 0 1-.118-.28 3.466 3.466 0 0 1 1.257-2.373A3.933 3.933 0 0 1 14 9.995a3.935 3.935 0 0 1 2.625.94 3.46 3.46 0 0 1 1.26 2.373.334.334 0 0 1-.118.28.45.45 0 0 1-.294.119.488.488 0 0 1-.31-.107.447.447 0 0 1-.161-.272A2.813 2.813 0 0 0 16 11.537a3.075 3.075 0 0 0-2-.7v.015Zm0-2.47a5.53 5.53 0 0 0-3.805 1.423 5.165 5.165 0 0 0-1.765 3.531.443.443 0 0 1-.742.27.348.348 0 0 1-.118-.281c.052-.79.265-1.562.627-2.274.34-.692.81-1.319 1.39-1.847a6.451 6.451 0 0 1 2.006-1.221 6.597 6.597 0 0 1 2.408-.444 6.569 6.569 0 0 1 2.419.444 6.433 6.433 0 0 1 2.01 1.22 6.262 6.262 0 0 1 1.407 1.831 5.92 5.92 0 0 1 .639 2.274.333.333 0 0 1-.118.28.469.469 0 0 1-.765-.28 5.156 5.156 0 0 0-1.766-3.531A5.523 5.523 0 0 0 14 8.38Z"
          fill="#000"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default IconLiveNew
