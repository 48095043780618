import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const IconDateFilledOutlinedAlt = props => (
  <SvgIcon {...props}>
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={14} cy={14} r={14} fill="#FFBE00" />
      <g opacity={0.7}>
        <path
          d="M17.333 7.333c-.366 0-.666.3-.666.667v.667h-5.334V8c0-.367-.3-.667-.666-.667-.367 0-.667.3-.667.667v.667h-.667c-.74 0-1.326.6-1.326 1.333L8 19.333c0 .734.593 1.334 1.333 1.334h9.334c.733 0 1.333-.6 1.333-1.334V10c0-.733-.6-1.333-1.333-1.333H18V8c0-.367-.3-.667-.667-.667Zm1.334 12H9.333v-6.666h9.334v6.666Zm-5.334-4.666c0-.367.3-.667.667-.667.367 0 .667.3.667.667 0 .366-.3.666-.667.666a.669.669 0 0 1-.667-.666Zm-2.666 0c0-.367.3-.667.666-.667.367 0 .667.3.667.667 0 .366-.3.666-.667.666a.669.669 0 0 1-.666-.666Zm5.333 0c0-.367.3-.667.667-.667.366 0 .666.3.666.667 0 .366-.3.666-.666.666a.669.669 0 0 1-.667-.666Zm-2.667 2.666c0-.366.3-.666.667-.666.367 0 .667.3.667.666 0 .367-.3.667-.667.667a.669.669 0 0 1-.667-.667Zm-2.666 0c0-.366.3-.666.666-.666.367 0 .667.3.667.666 0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667Zm5.333 0c0-.366.3-.666.667-.666.366 0 .666.3.666.666 0 .367-.3.667-.666.667a.669.669 0 0 1-.667-.667Z"
          fill="#000"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default IconDateFilledOutlinedAlt
